import React, { useContext, useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import BackendApiService from '../service/BackendApiService';
import { useOktaAuth } from '@okta/okta-react';
import { AppConfigContext } from '../context';
import SnackbarFeedback from '../components/SnackbarFeedback';
import { useMemo } from 'react';

export default function HistoryAffectations() {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const [historyAffectations, setHistoryAffectations] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const service = useMemo(
    () => new BackendApiService(appConfig.app.backendApiUrl, authState),
    [appConfig.app.backendApiUrl, authState]
  );

  useEffect(() => {
    const fetchHistoryAffectations = async () => {
      setLoading(true);
      let response;
      try {
        let page = 1;
        const size = 20000;
        let listHistory = [];
        do {
          response = await service.fetchHistoryAffectations(page, size);
          if (response.status === 404) break;
          const data = await response.json();
          listHistory = [...listHistory, ...data];
          page++;
        } while (response.status === 200);
        setHistoryAffectations(listHistory);
      } catch (error) {
        console.error(error);
        if (response && response.status === 401) {
          handleOpenSnackbar({
            severity: 'error',
            msg: 'Opération non autorisée',
          });
        } else if (response && response.status === 403) {
          handleOpenSnackbar({
            severity: 'error',
            msg: "Vous n'avez pas le droit pour effectuer cette opération",
          });
        } else if (response && response.status === 404) {
          handleOpenSnackbar({
            severity: 'error',
            msg: "Aucun collaborateur n'est rattaché à cette agence",
          });
        } else {
          handleOpenSnackbar({
            severity: 'error',
            msg: 'Erreur survenue: ' + error.message,
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchHistoryAffectations();
  }, [service]);

  const handleOpenSnackbar = function (feedback) {
    setFeedback(feedback);
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = function () {
    setOpenSnackbar(false);
  };

  const renderEmptyValue = (params) =>
    params.value === '' ? '_' : params.value;
  const columns = [
    { field: 'zoneCode', headerName: 'Zone code', width: 100 },
    { field: 'zoneNom', headerName: 'Zone nom', width: 300 },
    { field: 'refMetier', headerName: 'Ref metier', width: 100 },
    { field: 'zoneCodesPostaux', headerName: 'Zone codes postaux', width: 300 },
    {
      field: 'oldRefIndividu',
      headerName: 'Ancien ref individu',
      width: 150,
      renderCell: renderEmptyValue,
    },
    {
      field: 'newRefIndividu',
      headerName: 'Nouveau ref individu',
      width: 150,
      renderCell: renderEmptyValue,
    },
    {
      field: 'oldRefAgence',
      headerName: 'Ancien ref agence',
      width: 150,
      renderCell: renderEmptyValue,
    },
    {
      field: 'newRefAgence',
      headerName: 'Nouveau ref agence',
      width: 150,
      renderCell: renderEmptyValue,
    },
    { field: 'date', headerName: 'Date', width: 200 },
    { field: 'responsable', headerName: 'Responsable', width: 100 },
  ];

  return (
    <div>
      <DataGrid
        autoHeight
        rows={historyAffectations}
        columns={columns}
        loading={loading}
        localeText={{
          noRowsLabel: 'Aucune donnée à afficher',
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 14 },
            labelRowsPerPage: 'Ligne par page',
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Ligne par page',
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} / ${count === -1 ? '' : count}`,
          },
        }}
        getRowId={(row) => row.idHistorique}
      />
      <SnackbarFeedback
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        feedback={feedback}
      />
    </div>
  );
}
