import { useContext } from 'react';
import './App.css';
import Login from './Login';
import { AppConfigContext } from './context';

import { Box, CssBaseline, Toolbar } from '@mui/material';
import { Route, useHistory } from 'react-router-dom';

import LeftDrawer from './components/LeftDrawer';
import TopBar from './components/TopBar';
import SecteurAgence from './containers/SecteurAgence';
import SecteurChargeDev from './containers/SecteurChargeDev';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import UserAdmin from './containers/UserAdmin';
import ListAffecatations from './containers/ListAffectations';
import HistoryAffectations from './containers/HistoryAffectations';
import SecteurDirectionVente from './containers/SecteurDirectionVente';


const drawerWidth = 240;

function App() {

  const appConfig = useContext(AppConfigContext);

  const history = useHistory();

  const oktaAuth = new OktaAuth(appConfig.oidc);

  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };


  return (
    <Box sx={{ display: 'flex' }}>

      <CssBaseline />

      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}>

        <TopBar drawerWidth={drawerWidth} />

        <LeftDrawer drawerWidth={drawerWidth} />

        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>

          <Toolbar />

          <Route exact path="/login" render={() => <Login />} />
          <Route exact path="/login/callback" component={LoginCallback} />

          <SecureRoute exact path="/" component={SecteurAgence} />

          <SecureRoute exact path="/cd" component={SecteurChargeDev} />

          <SecureRoute exact path="/dv" component={SecteurDirectionVente} />

          <SecureRoute exact path="/affectations" component={ListAffecatations} />

          <SecureRoute exact path="/historiqueAffectations" component={HistoryAffectations} />

          <SecureRoute exact path="/user/admin" component={UserAdmin} />

        </Box>

      </Security>

    </Box >
  );
}

export default App;
