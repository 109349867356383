import {
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Box,
  SvgIcon,
} from '@mui/material';

const LegendCircleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <circle cx='12' cy='12' r='10' stroke='#6d6d6d' strokeWidth='1' />
    </SvgIcon>
  );
};

export default function Legend(props) {
  const { isDVLegend, theme } = props;

  const legend = [
    { label: 'Secteur GL affecté', color: theme.palette.gl?.main },
    { label: 'Secteur COPRO affecté', color: props.theme.palette.copro?.main },
    { label: 'Secteur disponible', color: 'white' },
    { label: 'Secteur occupé', color: '#f1b4b3' },
    { label: 'Secteur non disponible', color: '#c0c0c0' },
  ];

  const dVLegend = [
    { label: 'Secteur DV affecté', color: '#b0c4dE' },
    { label: 'Secteur disponible', color: 'white' },
    { label: 'Secteur occupé', color: '#f1b4b3' },
    { label: 'Secteur multi DV', color: '#D2B4DE' },
  ];

  const LegendItem = ({ color, label }) => {
    return (
      <ListItem>
        <ListItemIcon className='legend-item'>
          <LegendCircleIcon sx={{ color }} />
        </ListItemIcon>
        <ListItemText secondary={label} />
      </ListItem>
    );
  };

  return (
    <Box>
      <List dense={true}>
        {!isDVLegend ? (
          <>
            {legend.map(({ color, label }, index) => (
              <LegendItem key={index} color={color} label={label} />
            ))}
          </>
        ) : (
          <>
            {dVLegend.map(({ color, label }, index) => (
              <LegendItem key={index} color={color} label={label} />
            ))}
          </>
        )}
      </List>
    </Box>
  );
}
