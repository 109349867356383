import _ from 'lodash';

const metiers = { 'gl': 'GL', 'copro': 'COP', 'tr': 'TR' };

class BackendApiService {

    constructor(backendApiUrl, authState) {
        this.baseUrl = backendApiUrl;

        this.headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`
        };
    }

    fetchUserInfo = function () {
        return this.get("/userInfo", this.headers);
    }

    fetchUsers = function () {
        return this.get("/users", this.headers);
    }

    fetchChargeDevList = function (societe, metier) {
        let data = {
            "refCabinet": societe.refSiFinance,
            "metier": metiers[metier],
        };

        return this.postJson("/chargesDeveloppements", data);
    }

    fetchSocietes = function (nomRegion) {
        let data = {
            "region": nomRegion,
            "metiers": [metiers.gl, metiers.copro]
        };

        return this.postJson("/cabinetsByRegion", data);
    }

    fetchEtablissements = function (nomRegion) {
        let data = {
            "region": nomRegion,
            "metiers": [metiers.gl, metiers.copro]
        };

        return this.postJson("/agences", data);
    }

    saveRemplacementChargeDev = function (socRefSiFinance, currentChargeDevId, newChargeDevId) {
        let data = {
            "refCabinet": socRefSiFinance,
            "oldIdIndividu": currentChargeDevId,
            "newIdIndividu": newChargeDevId
        };

        console.log('socRefSiFinance', socRefSiFinance, "currentChargeDevId", currentChargeDevId, "newChargeDevId", newChargeDevId);
        console.log('data', data);

        return this.postJson("/updateChargesDeveloppements", data);
    }

    saveManagerGL = function (refAgence, newManagerGL) {
        let data = {
            "refAgence": refAgence,
            "refIndividu": newManagerGL,
        };

        console.log('data', data);

        return this.put("/updateManagerGl", data);
    }

    fetchCollabs = function (refAgence) {
        return this.get(`/agenceCollabs/refAgence/${refAgence}`, this.headers);
    }
    
    saveAffectationsEtablissement = function (etablissement, metier, zones) {
        let data = {
            typeAffectation: "SECTEUR_AGENCE",
            refAgence: etablissement.refSiFinance,
            metier: metiers[metier],
            zones
        };
        return this.postJson("/affectation", data);
    }

    saveAffectationsEtablissementDV = function (etablissement, metier, zones) {
      let data = {
            typeAffectation: "SECTEUR_DV",
            refAgence: etablissement.refSiFinance,
            metier: metiers[metier],
            zones,
      };
      return this.postJson("/affectation", data);
    };

    saveUser = function (user) {
        return this.postJson("/user", user);
    }

    deleteUser = function (idUser) {
        return this.delete(`/users/${idUser}`);
    }

    removeManagerGL = function (refAgence) {
        return this.delete(`/agences/${refAgence}/managerGL`);
    }

    saveAffectationsChargeDev = function (societe, zones, metier) {

        let affectationZoneList = [];

        for (let i in zones) {
            let zone = zones[i];

            let agence = this.findAgenceByZoneCode(societe.agences, zone.code, metier);

            if (agence) {
                let affZone = { typeAffectation: "CHARGE_DEV", refAgence: agence.refSiFinance, metier: metiers[metier] };

                let affZoneFound = _.find(affectationZoneList, affZone);
                if (affZoneFound) {
                    affZoneFound.zones.push(zone);
                } else {
                    affZone.zones = [zone];
                    affectationZoneList.push(affZone);
                }
            }
        }

        let all = affectationZoneList.map(data => this.postJson("/affectationCD", data));

        return Promise.allSettled(all);
    }

    findAgenceByZoneCode = function (agences, zoneCode, metier) {

        for (let i in agences) {
            let agence = agences[i];
            let zones = agence[metier];
            for (let j in zones) {
                let zone = zones[j];
                if (zone.code === zoneCode)
                    return agence;
            }
        }
        return null;
    }

    fetchAffectationsCount = function () {
        return this.get("/affectations/count", this.headers);
    }

    fetchAffectations = function (page, size) {
        const headers = {
            ...this.headers,
            "page": page,
            "size": size
        }
        return this.get("/affectations", headers);
    }

    fetchAgencesTransac = function (zoneDCId) {
        let data = {
            "zoneDCId": zoneDCId,
        };
        return this.postJson("/agences/transac", data);
    }

    saveManagerTransac = function (refAgence, newManagerGL) {
        let data = {
            "refAgence": refAgence,
            "refIndividu": newManagerGL,
        };

        console.log('data', data);

        return this.put("/agences/managerTransac", data);
    }

    removeManagerTransac = function (refAgence) {
        return this.delete(`/agences/${refAgence}/managerTransac`);
    }

    fetchAgencesTransacWithZoneDc = function () {
        return this.get("/agences/transacDc", this.headers);
    }

    fetchHistoryAffectations = function (page, size) {
        const headers = {
            ...this.headers,
            page, 
            size
        }
        return this.get("/historyAffectations", headers);
    }

    postJson = function (uri, json) {
        return fetch(this.baseUrl + uri, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(json)
        });
    }

    get = function (uri, headers) {
        return fetch(this.baseUrl + uri, {
            headers
        });
    }

    delete = function (uri) {
        return fetch(this.baseUrl + uri, {
            method: 'DELETE',
            headers: this.headers
        });
    }

    put = function (uri, json) {
        return fetch(this.baseUrl + uri, {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(json)
        });
    }
}

export default BackendApiService;